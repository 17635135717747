<template>
	<PageLayout title="Companies" desc="Create companies and add accounts" :loading="loading || isCreating">
	  <RouterLink custom :to="{ name: ONBOARDING_ROUTE_NAMES.COMPANIES_LIST }" v-slot="{ href, navigate }" v-if=" companies && companies.length > 0">
		  <Button back text="BACK TO COMPANIES LIST" :href="href" @click="navigate" style = "margin-top:-20px;margin-bottom: 20px;">
			  <template v-slot:icon>
				  <BackIcon/>
			  </template>
		  </Button>
	  </RouterLink>
	  <h3 style="margin-top:0;">Add a new company</h3>
	  <div class="layout">
			<CompanySettingsForm
					@submit="createNewCompany(company)"
					@add-account="addAccount"
					@edit-account="editAccount"
					@delete-account="deleteAccount"
					@add-currency="addCurrency"
					@add-payment-type="addPaymentType"
					@delete-currency="deleteCurrency"
					@delete-payment-type="deletePayment"
					:company-data="company"
					:show-switcher="false"
			/>
		</div>
	</PageLayout>
</template>

<script setup>
import PageLayout from '@/components/layouts/PageLayout.vue';
import Button from "@/components/base/AppButton";
import BackIcon from "@/assets/icons/back.svg";
import CompanySettingsForm from "@/views/admin/companies/components/CompanySettingsForm";
import {ONBOARDING_ROUTE_NAMES} from "@/constants/route-names";
import useCreateCompany from "@/hooks/companies/useCreateCompanie";
import {useRouter} from "vue-router";
import {reactive} from 'vue';
import useCompaniesList from "@/hooks/companies/useCompaniesList";

const {createCompany, loading, loading: isCreating,} = useCreateCompany();
const {companies} = useCompaniesList();
const router = useRouter()

let company = reactive({
	name: '',
	accounts: [
		{
			name: "Account name",
			id: 0,
			currencies: [
				{id: 0, name: "USD"},
				{id: 1, name: "EUR"},
			],
			"payment_types": [
				{id: 0, name: "Cash"},
				{id: 1, name: "Credit Card"},
			]
		}
	],
})
const createDefaultAccount = (id, name) => {
	return {
		name: name,
		id: id,
		currencies: [
			{id: 0, name: "USD"},
			{id: 1, name: "EUR"},
		],
		"payment_types": [
			{id: 0, name: "Cash"},
			{id: 1, name: "Credit Card"},
		]
	}
};
const addAccount = (name) => {
	const k = company.accounts.length
	const newAcc = createDefaultAccount(k, name);
	company.accounts.push(newAcc);
}
const editAccount = (id, name) => {
	company.accounts[id].name = name.name;
};
const deleteAccount = (accountId) => {
	company.accounts.splice(accountId, 1);
}
const addCurrency = ({id, name}) => {
	const lastEl = company.accounts[id].currencies.slice(-1);
	company.accounts[id].currencies.push({id: lastEl[0].id + 1, name: name.name})
}
const deleteCurrency = ({accountId, currencyId}) => {
	let ArrayOfCurrencies = company.accounts[accountId].currencies;
	company.accounts[accountId].currencies = ArrayOfCurrencies.filter(item => item.id !== currencyId)
}
const addPaymentType = ({id, name}) => {
	const lastEl = company.accounts[id].payment_types.slice(-1);
	company.accounts[id].payment_types.push({id: lastEl[0].id + 1, name: name.name})
}
const deletePayment = ({accountId, paymentId}) => {
	let ArrayOfPayments = company.accounts[accountId].payment_types;
	company.accounts[accountId].payment_types = ArrayOfPayments.filter(item => item.id !== paymentId)
}
const createNewCompany = async (data) => {
	loading.value = true;
	data.accounts.forEach((item) => {
		delete item['id']
	})
	const response = await createCompany(data);
	if(response.success){
	  router.push({name: ONBOARDING_ROUTE_NAMES.COMPANIES_LIST})
	} else {
	  loading.value = false;
	}

}
</script>
<style>
h3 {
	margin: 34px 0;
}
</style>

